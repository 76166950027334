// ********************* STYLES ********************* //
import '../styles/ShareProfileCSS.css';
import { API_BASE_URL } from '../utils/varConstant';
// ********************* STYLES ********************* //

// ******************* REACT JS IMPORT LIBRARY ******************************/ /
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// import {
//     CButton,
//     CCard,
//     CCardBody,
//     CCardGroup,
//     CCol,
//     CContainer,
//     CForm,
//     CFormInput,
//     CInputGroup,
//     CInputGroupText,
//     CRow,
// } from '@coreui/react'
// import CIcon from '@coreui/icons-react';
// import { cilLockLocked, cilUser } from '@coreui/icons';
// import 'bootstrap/dist/css/bootstrap.min.css';
import axios from "axios"
// ******************* REACT JS IMPORT LIBRARY ******************************//


// ******************* IMAGES ******************************//
import XureLogo from '../assets/New-Xure-Logo.png';
import XLogo from '../assets/XLogo.png';
import DefaultProfile from '../assets/DefaultProfile.png';
import profileVerified from '../assets/xure-icon/icon-profile-verified.png';
import googlePlay from '../assets/xure-icon/googlePlay.png';
import appStore from '../assets/xure-icon/appStore.png';
import { Spinner } from '@material-tailwind/react';
// ******************* IMAGES ******************************//

const SAMPLE_DATA = {
    "user_profile_id": "4",
    "user_profile_type": "Member",
    "username": "benny",
    "fullname": "John Sheena",
    "picture_link": "https://xureapptestbucket.s3.ap-southeast-1.amazonaws.com/XureApp/public/images/2024/07/17/PH/profile/profile_MC-1000000003_2024_07_17_11_59_40?AWSAccessKeyId=AKIA4BK6Q6EFDJRDMDKV&Expires=1726207481&Signature=f3u2ZQea2KrkOBAFpp0FyivzFcQ%3D",
    "account_status": "Basic",
    "display_name": "John Sheena"
}

const ShareProfile = () => {
    const [profileData, serProfileData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getData()
    }, []);

    const getData = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const value = urlParams.get('profile_code');

        try {
            const endpoint = `${API_BASE_URL}/api/xdeal/viewprofile`
            const inputData = {
                profile_code: value,
            }
            const response = await axios.post(endpoint, inputData);
            if (typeof response.data === "object") {
                let codes = response.data;
                codes.profile_code = value
                serProfileData(codes);
            }
            else if (response.data === "User is unavailable") {
                serProfileData(null);
            }
        }
        catch (error) {
            console.log(error)
        }
        finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000)
        }
    }

    const navigateToApp = async () => {
        const appScheme = `xuredeal://share_profile/${profileData.profile_code}/${profileData.user_profile_id}/${profileData.user_profile_type}`;
        const appStoreURL = 'https://apps.apple.com/ph/app/xure/id6463198157'; // Replace with your App Store ID
        const playStoreURL = 'https://play.google.com/store/apps/details?id=com.xdeal&hl=en_US'; // Replace with your Play Store package name
        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        const storeURL = isIOS ? appStoreURL : playStoreURL;
        const isAppInstalled = await checkAppInstalled(appScheme); // Replace with actual check

        if (isAppInstalled) {
            window.location.href = appScheme;
        } else {
            window.location.href = storeURL;
        }
    }

    function checkAppInstalled(scheme) {
        return new Promise(resolve => setTimeout(() => resolve(false), 1000));
    }


    return (
        <div className="flex items-center justify-center h-full min-h-screen flex-col selector ">
            <img className="mb-5 align-self-center" src={XureLogo} alt="" width="200" height="50" />
            {/* <div>
            <div className="flex flex-col justify-content-center">

                <div className='PanelCard flex flex-column'>
                    {loading ?
                        (
                            <div className='mb-3 flex flex-col items-center justify-center'>
                                <div className="spinner-border text-light" role="status" />
                            </div>

                        )
                        :
                        <>
                            <div className='profile-container'>
                                <div className='profile-img'>
                                    <img src={profileData?.picture_link ? profileData?.picture_link : DefaultProfile} className='img-fluid rounded-full' />
                                </div>
                            </div>
                            <div className='flex flex-row justify-center'>
                                <h4 className='text-light fw-bold'>
                                    {profileData?.user_profile_type === "Xpert" ? profileData?.fullname : profileData?.display_name}
                                </h4>

                                {profileData?.account_status === "Verified" && (
                                    <div className='profile-verified ms-2'>
                                        <img src={profileVerified} className='img-fluid' />
                                    </div>
                                )}
                            </div>
                            <h5 className='text-light mb-3 text-center'>@{profileData?.username}</h5>
                            <CButton className="btn-main mb-4" type="submit" onClick={navigateToApp}>
                                    Follow
                                </CButton>
                        </>
                    }



                    <h5 className='text-light mb-2 text-center'>DOWNLOAD NOW!</h5>
                    <div className='flex flex-row justify-center'>

                        <CButton className='col-lg-2 col-lg-2 me-2 p-0 btn-download'>
                                <a href="https://play.google.com/store/apps/details?id=com.xdeal&pli=1" target='_blank'>

                                    <img src={googlePlay} className='img-fluid' />
                                </a>
                            </CButton>

                            <CButton className='col-lg-2 col-lg-2 p-0 btn-download'>
                                <a href="https://apps.apple.com/ph/app/xure/id6463198157" target='_blank'>
                                    <img src={appStore} className='img-fluid' />
                                </a>
                            </CButton>
                    </div>
                </div>
            </div>
            </div> */}

            <ul className='p-4 md:p-8 text-white'>
                <li className='PanelCard w-[22rem] md:w-[40rem] lg:w-[50rem] h-full  border-radius-[30px]'>
                    {loading ?
                        (
                            <div className='mb-3 flex flex-col items-center justify-center '>
                                <Spinner className="h-[10rem] w-[10rem]" color="amber" />
                            </div>

                        )
                        :
                        <>
                            {
                                profileData &&
                                <>
                                    <div className='profile-container'>
                                        <div className='profile-img'>
                                            <img src={profileData?.picture_link ? profileData?.picture_link : DefaultProfile} className='img-fluid rounded-full' />
                                        </div>
                                    </div>
                                    <div className='flex flex-row justify-center text-xl flex-wrap'>
                                        <div className='flex items-center'>
                                            <p className="text-light font-bold text-lg truncate sm:truncate-none">
                                                {profileData?.user_profile_type === "Xpert" ? profileData?.fullname : profileData?.display_name}
                                            </p>
                                            {profileData?.account_status === "Verified" && (
                                                <span className='profile-verified ms-2'>
                                                    <img src={profileVerified} className='img-fluid' />
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <h5 className='text-light mb-3 text-center'>@{profileData?.username}</h5>
                                    <button className="btn-main mb-4 w-full font-semibold btn-animation" onClick={navigateToApp}>Follow</button>
                                </>

                            }



                        </>
                    }


                    <h5 className='text-light mb-2 text-center'>DOWNLOAD NOW!</h5>

                    <div className='flex flex-row justify-center items-center gap-3 md:gap-5'>
                        <button className='btn-animation'>
                            <a href="https://play.google.com/store/apps/details?id=com.xdeal&pli=1" target='_blank' className='btn-animation'>
                                <img src={googlePlay} className='img-fluid w-[118px]' />
                            </a>
                        </button>
                        <button className='btn-animation'>
                            <a href="https://apps.apple.com/ph/app/xure/id6463198157" target='_blank' >
                                <img src={appStore} className='img-fluid w-[118px]' />
                            </a>
                        </button>
                    </div>
                </li>

            </ul>

        </div >
    )
}

export default ShareProfile
