import React from "react"
// import logo from "../assets/Logo-X.png"
import { IoLogoGooglePlaystore } from "react-icons/io5";
import { FaAppStoreIos } from "react-icons/fa";
import { BsFacebook, BsInstagram, BsTiktok, BsYoutube, BsTwitterX } from "react-icons/bs";
import { Link } from 'react-scroll';
import { BsFillTelephoneFill, BsChatDotsFill } from "react-icons/bs";
import logo from '../assets/logo.png'
export default function Footer() {

    return (
        <div className="w-full z-30 bg-[#161616]" id="footer">
            <div className="border-b-2  border-gray-800 w-full flex-col lg:flex-row flex justify-evenly items-start py-5 text-[#676767] 2xl:gap-20">

                {/* fist container */}
                <div className="flex w-full  lg:justify-start xl:justify-end  lg:px-10  xl:px-20">
                    {/* logo */}
                    <ul className="w-full text-center items-center lg:items-start 3xl:items-center flex flex-col gap-2 text-sm md:text-base">
                        <li className="w-[6rem] mb-2 lg:ml-[85px] 3xl:ml-[25px]">
                            <img src={logo} alt="logo" />
                        </li>
                        <li className=" font-[700] mb-2">
                            Authenticate, Showcase and Trade
                        </li>
                        <li className=" font-[500] w-[280px]">
                            Indulge in a world of prized possessions. Buy, sell, and display your authenticated treasures. Connect with fellow enthusiasts and build your unique collection
                        </li>

                    </ul>
                </div>

                {/* second container */}
                <div className="flex gap-[40px] md:gap-[100px] lg:gap-[60px] xl:gap-[100px] lg:-ml-[250px] xl:ml-3 xl:-m-0  flex-col md:flex-row items-center md:items-start justify-center lg:justify-end lg:pr-10 xl:pr-20 3xl:justify-start w-full mt-10 lg:mt-0 text-sm md:text-base">
                    {/**Resources */}
                    <ul className=" gap-3 flex flex-col  text-center lg:text-start order-1">
                        <li className=" text-neutral-400 font-[700] uppercase">
                            Resources
                        </li>
                        <li className=" hover:text-[#ceae7b] text-neutral-500  font-[500] cursor-pointer ">
                            Attribution
                        </li>
                        <li className=" hover:text-[#ceae7b]  text-neutral-500 font-[500] cursor-pointer ">
                            FAQS
                        </li>
                    </ul>

                    {/**Our Company */}
                    <div className="gap-3 flex flex-col md:order-3 lg:order-2">
                        <p className=" text-center lg:text-start  text-neutral-400  font-[700] uppercase">
                            Our Company
                        </p>
                        <div className="flex gap-5">
                            <ul className=" gap-3 flex flex-col xl:w-[7rem] text-center lg:text-start">

                                <Link to="home" spy={true} smooth={true} duration={500}>
                                    <li className=" hover:text-[#ceae7b]  text-neutral-500  font-[500] cursor-pointer ">
                                        Home
                                    </li>
                                </Link>
                                <Link to="XureDealWorks" spy={true} smooth={true} duration={500}>
                                    <li className=" hover:text-[#ceae7b]  text-neutral-500 font-[500] cursor-pointer ">
                                        How it works
                                    </li>
                                </Link>
                                <Link to="contactUs" spy={true} smooth={true} duration={500}>
                                    <li className=" hover:text-[#ceae7b]  text-neutral-500  font-[500] cursor-pointer ">
                                        Contact Us
                                    </li>
                                </Link>
                                {/* <Link to="xhibition" spy={true} smooth={true} duration={500}>
                    <li className="hover:text-[#ceae7b] text-neutral-500 font-[500] cursor-pointer ">
                        Xhibition
                    </li>
                </Link> */}
                            </ul>
                            {/* <ul className=" gap-3 flex flex-col xl:w-[7rem] text-center lg:text-start">
                <Link to="xhibition" spy={true} smooth={true} duration={500}>
                    <li className="hover:text-[#ceae7b] text-neutral-500 font-[500] cursor-pointer ">
                        Xhibition
                    </li>
                </Link>
                <Link to="xperts" spy={true} smooth={true} duration={500}>
                    <li className=" hover:text-[#ceae7b]  text-neutral-500  font-[500] cursor-pointer ">
                        Xperts
                    </li>
                </Link>
                <Link to="authenticate" spy={true} smooth={true} duration={500}>
                    <li className=" hover:text-[#ceae7b]  text-neutral-500  font-[500] cursor-pointer ">
                        Xertify
                    </li>
                </Link>
                </ul> */}
                        </div>
                    </div>

                    {/* Social Media */}
                    <ul className="flex flex-col gap-5 xl:w-[8.9rem] text-center lg:text-start md:order-2 lg:order-3">
                        <li className=" text-neutral-400  font-[700] uppercase  justify-center md:justify-start flex">
                            Social Media
                        </li>
                        <li className="flex gap-4 text-2xl w-full justify-center lg:justify-start">
                            <a href="https://www.facebook.com/xureofficial" target="_blank" rel="noreferrer" className="hover:text-[#ceae7b]">
                                <BsFacebook />
                            </a>
                            <a href="https://www.instagram.com/xureofficial/" target="_blank" rel="noreferrer" className="hover:text-[#ceae7b]">
                                <BsInstagram />
                            </a>
                            <a href="https://www.tiktok.com/" target="_blank" rel="noreferrer" className="hover:text-[#ceae7b]">
                                <BsTiktok />
                            </a>
                            <a href="https://twitter.com/xureofficial" target="_blank" rel="noreferrer" className="hover:text-[#ceae7b]" >
                                <BsTwitterX />
                            </a>
                            <a href="https://www.youtube.com/" target="_blank" rel="noreferrer" className="hover:text-[#ceae7b]">
                                <BsYoutube />
                            </a>
                        </li>
                        <li className="text-neutral-400  font-[700] uppercase flex justify-center md:justify-start ">
                            Download Now
                        </li>
                        <li className="flex gap-4 text-2xl  w-full justify-center lg:justify-start ">
                            <a href="https://apps.apple.com/ph/app/xure/id6463198157" target="_blank" className="">
                                <FaAppStoreIos />
                            </a>
                            <a href="https://play.google.com/store/apps/details?id=com.xdeal" target="_blank" className="">
                                <IoLogoGooglePlaystore />
                            </a>
                        </li>
                        <li className="text-neutral-400  font-[700] uppercase flex justify-center md:justify-start  ">
                            contact us
                        </li>
                        <li className="flex gap-2 items-center w-full justify-center lg:justify-start -mt-2">
                            <p><BsFillTelephoneFill /></p>
                            <p>65-6266 1132</p>
                        </li>

                    </ul>
                </div>

            </div>



            {/* Lower part */}
            <div className="flex flex-col lg:flex-row w-full text-center justify-center items-center gap-3 md:gap-2 lg:px-10 xl:px-20 px-2  py-1 text-[11px] md:text-[14px]">
                {/* <ul className="font-[500] text-[#676767]  flex lg:w-[80rem] xl:w-full justify-evenly xl:justify-start  order-2  lg:-ml-7 xl:-ml-[190px] 2xl:-ml-[280px]
                "> */}
                <ul className="font-[500] text-[#676767]  flex lg:w-[80rem] xl:w-full justify-evenly xl:justify-start  order-2  
                ">
                    <li className="hover:underline cursor-pointer border-r-2 border-[#676767] md:px-2 px-1">About Us</li>
                    <li className="hover:underline cursor-pointer  border-r-2 border-[#676767] md:px-2 px-1">Privacy Policy</li>
                    <li className="hover:underline cursor-pointer  border-r-2 border-[#676767] md:px-2 px-1">Term of Use</li>
                    <li className="hover:underline cursor-pointer px-2">Help Center</li>
                </ul>
                <p className="font-[500] text-[#676767] lg:w-full  flex justify-center lg:justify-start  order-1">
                    © 2024 Xure Deal. All rights reserved.
                </p>
                <p className="font-[500] text-[#676767] lg:w-full flex  justify-center lg:text-right xl:justify-end order-3">
                    39/F, MBFC 2, #10 Marina Blvd. Singapore 018983
                </p>
            </div>
        </div>
    )

}