import React, { useState, useEffect } from 'react';


import Lottie from 'lottie-react'
import authenticator from "../assets/animate/authenticator.json"
import exchange from "../assets/animate/exchange.json"
import showcase from "../assets/animate/showcase.json"

import AOS from 'aos';
import 'aos/dist/aos.css';

import xhibition from '../assets/xhibitions.png'
import xperts from '../assets/Xperts.png'
import xchange from '../assets/xchange.png'



export default function ItWorks() {

    useEffect(() => {
        AOS.init();
      }, []);

    return (
        <div className="min-w-screen w-full min-h-screen h-full items-center justify-center bg-[#f5f5f5] flex" id="XureDealWorks">
                <ul className="flex flex-col items-center justify-center h-full py-10 lg:py-5 lg:px-[15px] md:px-[10px]">
                    <li className="font-bold text-md md:text-3xl 2xl:text-5xl capitalize w-full flex justify-center items-center bg-white h-[100px] px-[5px] py-2 md:h-[180px] rounded-xl "  data-aos="fade-up" data-aos-duration="1000">
                        Elevate Your Collecting Experience
                    </li>
                    <li className="flex flex-col md:flex-row items-start justify-start gap-5 mt-[22px]">
                        {/* desktop */}
                    <div className='border-2 border-white bg-white 2xl:w-[30rem] xl:w-[25rem] lg:w-[18rem] md:w-[13rem] w-[18rem]  h-[26rem] rounded-2xl px-4 text-center  py-4 hidden md:flex flex-col justify-center md:justify-start items-center  pt-10 lg:pt-5  xl:pt-10'  data-aos="fade-up" data-aos-duration="2200">
                    <div className='w-[130px] h-[100px]  sm:w-[180px] sm:h-[150px] lg:w-[230px] lg:h-[200px] flex items-end'  >
                                    {/* <Lottie animationData={authenticator}/> */}
                                    <img src={xperts}/>
                            </div>
                                <p className='text-lg font-bold lg:text-2xl my-3'>Our Xperts</p>
                                <p className=''> Our certified authenticators will meticulously examine your items to guarantee their authenticity.</p>
                        </div>
                        {/* mobile view */}
                        <div className='border-2 border-white bg-white 2xl:w-[30rem] xl:w-[25rem] lg:w-[18rem] md:w-[13rem] w-[18rem]  h-[26rem] rounded-2xl px-4 text-center md:hidden py-4 flex flex-col justify-center md:justify-start items-center  pt-10 lg:pt-5  xl:pt-10'  data-aos="fade-up" data-aos-duration="1000">
                        <div className='w-[130px] h-[100px]  sm:w-[180px] sm:h-[150px] lg:w-[230px] lg:h-[200px] flex items-end'  >
                                    {/* <Lottie animationData={authenticator}/> */}
                                    <img src={xperts}/>
                            </div>
                                <p className='text-lg font-bold lg:text-2xl my-3'>Our Xperts</p>
                                <p className=''> Our certified authenticators will meticulously examine your items to guarantee their authenticity.</p>
                        </div>
                        {/* desktop */}
                        <div className='border-2 border-white bg-white 2xl:w-[30rem] xl:w-[25rem] lg:w-[18rem] md:w-[13rem] w-[18rem] h-[26rem] rounded-2xl px-4 text-center py-4 hidden md:flex flex-col justify-center md:justify-start items-center pt-10 lg:pt-5  xl:pt-10' data-aos="fade-up" data-aos-duration="1600">
                                <div className='w-[130px] h-[100px]  sm:w-[180px] sm:h-[150px] lg:w-[230px] lg:h-[200px] flex items-end'  >
                                    {/* <Lottie animationData={showcase}/> */}
                                    <img src={xhibition}/>
                                </div>
                                <p className='text-lg font-bold lg:text-2xl my-3'>Your Xhibition</p>
                                <p className=''> Confidently showcase your prized possessions to a community of fellow enthusiasts and build your unique collection.</p>
                            
                        </div>
                        {/* mobile */}
                        <div className='border-2 border-white bg-white 2xl:w-[30rem] xl:w-[25rem] lg:w-[18rem] md:w-[13rem] w-[18rem] h-[26rem] rounded-2xl px-4 text-center py-4 flex md:hidden flex-col justify-center md:justify-start items-center pt-10 lg:pt-5  xl:pt-10' data-aos="fade-up" data-aos-duration="1000">
                                <div className='w-[130px] h-[100px]  sm:w-[180px] sm:h-[150px] lg:w-[230px] lg:h-[200px] flex items-end'  >
                                    {/* <Lottie animationData={showcase}/> */}
                                    <img src={xhibition}/>
                                </div>
                                <p className='text-lg font-bold lg:text-2xl my-3'>Your Xhibition</p>
                                <p className=''> Confidently showcase your prized possessions to a community of fellow enthusiasts and build your unique collection.</p>
                            
                        </div>
                        {/* desktop */}
                        <div className='border-2 border-white bg-white 2xl:w-[30rem] xl:w-[25rem] lg:w-[18rem] md:w-[13rem] w-[18rem] h-[26rem] rounded-2xl px-4 text-center py-4 hidden md:flex flex-col justify-center md:justify-start items-center pt-10 lg:pt-5  xl:pt-10'  data-aos="fade-up" data-aos-duration="1900">
                        <div className='w-[130px] h-[100px]  sm:w-[180px] sm:h-[150px] lg:w-[230px] lg:h-[200px] flex items-end'  >
                                    {/* <Lottie animationData={showcase}/> */}
                                    <img src={xchange}/>
                                </div>
                                <p className='text-lg font-bold lg:text-2xl my-3'>The Xchange</p>
                                <p className=''>Say goodbye to counterfeit concerns and dive into a world of trusted transactions, where every purchase and sale safeguards the credibility of your items.</p>
                            
                        </div>
                        {/* mobile */}
                        <div className='border-2 border-white bg-white 2xl:w-[30rem] xl:w-[25rem] lg:w-[18rem] md:w-[13rem] w-[18rem] h-[26rem] rounded-2xl px-4 text-center py-4 flex md:hidden flex-col justify-center md:justify-start items-center'  data-aos="fade-up" data-aos-duration="1000">
                        <div className='w-[130px] h-[100px]  sm:w-[180px] sm:h-[150px] lg:w-[230px] lg:h-[200px] flex items-end pt-10 lg:pt-5  xl:pt-10'  >
                                    {/* <Lottie animationData={showcase}/> */}
                                    <img src={xchange}/>
                                </div>
                                <p className='text-lg font-bold lg:text-2xl my-3'>The Xchange</p>
                                <p className=''>Say goodbye to counterfeit concerns and dive into a world of trusted transactions, where every purchase and sale safeguards the credibility of your items.</p>
                            
                        </div>
                    </li>
                    <li></li>
                    <li></li>
                </ul>
            
        </div>
    );
}