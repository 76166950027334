import logo from './logo.svg';
import './App.css';
import { useState } from "react";
import './index.css';
import NavigationBar from './components/NavigationBar';
import Home from './components/Home';
// import ItWorks from './components/ItWorks';
// import HowItWorks from './components/HowItWorks';
import ContactUs from './components/Contact';
import Footer from './components/Footer';
import Authenticate from './components/Authenticate';
import Description from './components/Description';
import { ToastContainer } from 'react-toastify';

import { BeatLoader } from 'react-spinners';
import Xperts from './components/Xperts';
import Xchange from './components/Xchange';
import XureAppHome from './components/XureAppHome';

import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import InvalidPage from './components/InvalidPage';
import ShareProfile from './components/ShareProfile';

function App() {
  const [isLoading, setIsLoading] = useState(true);
  setTimeout(() => {
    setIsLoading(false);
  }, 1500);

  const data = () => {
    return (
      <>
        < NavigationBar />
        <XureAppHome />
        {/* <Home /> */}
        <Description />
        {/* <HowItWorks /> */}
        {/* <Xchange /> */}
        {/* <Xperts /> */}
        {/* <Authenticate /> */}
        {/* <ContactUs /> */}
        <Footer />
        <ToastContainer />
      </>
    )
  }

  return (
    <div className="font-General-Sans">
      {/* {isLoading ? (
        <div className="flex items-center justify-center w-full h-screen bg-[#161616]">
          <BeatLoader color="#ceae7b" size={30} />
        </div>
      ) : (
        <> */}


      <BrowserRouter>
        <Routes>
          <Route path='/' element={data()} />
          <Route path='/support' element={<ContactUs />} />
          <Route path='/shareprofile' element={<ShareProfile />} />
          <Route path='*' element={<InvalidPage />} />
        </Routes>
      </BrowserRouter>

      {/* </> */}
      {/* )} */}
    </div>
  );
}

export default App;
